import React from "react"
import PropTypes from "prop-types"
import Datetime from "react-datetime"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGlobe } from "@fortawesome/pro-solid-svg-icons"
import { getDivisionTimeZone, getDivisionTimeZoneAbbreviation } from "js/includes/common/utils"
import { Box } from "js/includes/components/Styled"
import Tooltip from "js/includes/components/Tooltip"

const DatetimePickerWithTimezone = ({ dateTime, onDateTimeChange, width = "100%", isValidDate }) => {
  const divisionTimezone = getDivisionTimeZone()
  const parsedDatetime = dateTime.format("L LT")
  return (
    <Box display="flex" alignItems="center" width={width}>
      <Box minWidth={155} flex={1}>
        <Datetime
          value={parsedDatetime}
          onChange={onDateTimeChange}
          isValidDate={isValidDate}
          displayTimeZone={divisionTimezone}
        />
      </Box>
      <Box width={80} display="flex" justifyContent="center" alignItems="center">
        <Tooltip output={divisionTimezone}>
          <FontAwesomeIcon icon={faGlobe} />
        </Tooltip>
        <span className="p-l-sm white-space-nowrap">{getDivisionTimeZoneAbbreviation()}</span>
      </Box>
    </Box>
  )
}

export default DatetimePickerWithTimezone

DatetimePickerWithTimezone.propTypes = {
  dateTime: PropTypes.object.isRequired,
  onDateTimeChange: PropTypes.func.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
