import { compose } from "ramda"

function padString(input) {
  const segmentLength = 4
  const diff = input.length % segmentLength
  return !diff ? input : input.padEnd(input.length + segmentLength - diff, "=")
}

function toBase64URL(base64url) {
  return padString(base64url)
    .replace(/-/g, "+")
    .replace(/_/g, "/")
}

function fromBase64URL(input) {
  return input
    .replace(/=/g, "")
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
}

export const encodeBase64URL = compose(fromBase64URL, window.btoa)

export const decodeBase64URL = compose(window.atob, toBase64URL)
