import { compose, keys, map, match } from "ramda"
import fastDeepEqual from "fast-deep-equal"

import { sizer } from "@ninjaone/utils"

import {
  hasCustomSnmpViewOrUpdatePermission,
  hasDeviceAdministrationAccess,
} from "js/includes/common/services/userPermissions"
import {
  findRoute,
  isFeatureEnabled,
  isMDMFeatureEnabled,
  isSystemAdmin,
  localized,
  routeStartsWith,
  user,
} from "js/includes/common/utils"
import { appInfo, PSA_TYPES } from "js/includes/configuration/apps/appInfo"

export const administrationMaxWidth = "1680px"

// used to calc the max-width for views with gray backgrounds
export const administrationPaddingOffset = sizer(12)

export const adminMarginBottom = sizer(5)

// routes with gray backgrounds - needed to set padding and max-width
export const routesWithGrayBackgrounds = []

export const isGrayBackgroundPadding = () => {
  return findRoute(routesWithGrayBackgrounds)
}

export function getAdministrationNavItems({ appInfoCustomData = {} } = {}) {
  const administrationAliases = [localized("administration"), localized("settings")]

  const administrationNavItems = [
    // general tab
    {
      isActive: routeStartsWith("/administration/general"),
      to: user("getGeneralTabLink"),
      name: localized("General"),
      baseUrl: "/administration/general",
      showWhen: user("canConfigureSystemActivities"),
      subtabs: [
        ...(isSystemAdmin()
          ? [
              {
                name: localized("Settings"),
                route: "/administration/general/settings",
                searchAliases: [administrationAliases[0]],
              },
            ]
          : []),
        ...(user("canConfigureBranding")
          ? [
              {
                name: localized("Branding"),
                route: "/administration/general/branding/website",
                baseRoute: "/administration/general/branding",
                searchAliases: administrationAliases,
              },
            ]
          : []),
        ...(user("canConfigureSystemActivities")
          ? [
              {
                name: localized("Activities"),
                route: "/administration/general/activities",
                searchAliases: administrationAliases,
              },
            ]
          : []),
      ],
    },
    // accounts tab
    {
      name: localized("Accounts"),
      to: "/administration/users/technicians",
      isActive: routeStartsWith("/administration/users"),
      baseUrl: "/administration/users",
      subtabs: [
        {
          name: localized("Technicians"),
          route: "/administration/users/technicians",
          searchAliases: [localized("users"), ...administrationAliases],
        },
        ...(isSystemAdmin()
          ? [
              {
                name: localized("Technician Roles"),
                route: "/administration/users/technicianRoles",
                searchAliases: [localized("users"), ...administrationAliases],
              },
              {
                name: localized("Identity Providers"),
                route: "/administration/users/identityProviders",
                baseRoute: "/administration/users/identityProviders",
                searchAliases: [localized("users"), "sso", ...administrationAliases],
              },
              {
                name: localized("Technician Security Settings"),
                route: "/administration/users/technicianSecuritySettings",
                searchAliases: [localized("users"), ...administrationAliases],
              },
            ]
          : []),
        ...(user("canCRUDEndUserSharing")
          ? [
              {
                name: localized("End Users"),
                route: "/administration/users/endUsers",
                searchAliases: [localized("users"), ...administrationAliases],
              },
              {
                name: localized("End User Roles"),
                route: "/administration/users/endUserRoles",
                searchAliases: [localized("users"), ...administrationAliases],
              },
            ]
          : []),
        {
          name: localized("Contacts"),
          route: "/administration/users/contacts",
          searchAliases: [localized("users"), ...administrationAliases],
        },
      ],
    },
    // apps tab
    {
      name: localized("Apps"),
      to: "/administration/apps",
      isActive: routeStartsWith("/administration/apps"),
      baseUrl: "/administration/apps",
      subtabs: [
        {
          name: localized("Installed"),
          route: "/administration/apps",
          excludedTabs: ["api", "notificationChannels"],
          searchAliases: administrationAliases,
        },
        {
          name: localized("API"),
          route: "/administration/apps/api",
          searchAliases: administrationAliases,
        },
        {
          name: localized("Notification Channels"),
          route: "/administration/apps/notificationChannels",
          searchAliases: administrationAliases,
        },
        ...compose(
          map(appInfoKey => {
            const { configRoute, label, baseRoute, subtabs } = appInfo[appInfoKey]
            return {
              name: appInfoCustomData?.[appInfoKey]?.name || label(),
              route: configRoute,
              show: false,
              baseRoute,
              subtabs: subtabs?.map(({ label, route }) => ({
                name: label(),
                route,
              })),
            }
          }),
          keys,
        )(appInfo),
      ],
    },
    // devices tab
    {
      name: localized("Devices"),
      showWhen: hasDeviceAdministrationAccess(),
      to: "/administration/devices/groups",
      isActive: routeStartsWith("/administration/devices"),
      baseUrl: "/administration/devices",
      subtabs: [
        {
          name: localized("Groups"),
          route: "/administration/devices/groups",
          searchAliases: administrationAliases,
        },
        {
          name: localized("Roles"),
          route: "/administration/devices/roles",
          searchAliases: administrationAliases,
        },
        {
          name: localized("Role Custom Fields"),
          route: "/administration/devices/roleCustomFields",
          searchAliases: administrationAliases,
        },
        {
          name: localized("Global Custom Fields"),
          route: "/administration/devices/globalCustomFields",
          searchAliases: administrationAliases,
          subtabs: [
            {
              name: localized("Device"),
              route: "/administration/devices/globalCustomFields/node",
            },
            {
              name: localized("Location"),
              route: "/administration/devices/globalCustomFields/location",
            },
            {
              name: localized("Organization"),
              route: "/administration/devices/globalCustomFields/organization",
            },
          ],
        },
        {
          name: localized("Health Status"),
          route: "/administration/devices/healthStatus",
          searchAliases: administrationAliases,
        },
        ...(isFeatureEnabled("node_caching") && isSystemAdmin()
          ? [
              {
                name: localized("Cache"),
                route: "/administration/devices/cache",
                searchAliases: administrationAliases,
              },
            ]
          : []),
      ],
    },
    // library tab
    {
      name: localized("Library"),
      to: "/administration/library/downloads",
      isActive: routeStartsWith("/administration/library"),
      baseUrl: "/administration/library",
      subtabs: [
        {
          name: localized("Downloads"),
          route: "/administration/library/downloads",
          searchAliases: administrationAliases,
        },
        ...(isFeatureEnabled("custom_snmp") && hasCustomSnmpViewOrUpdatePermission()
          ? [
              {
                name: localized("SNMP Library"),
                route: "/administration/library/snmpLibrary",
                searchAliases: administrationAliases,
              },
            ]
          : []),
        ...(user("canReadAtLeastOneScript")
          ? [
              {
                name: localized("Automation"),
                searchAliases: [localized("scripts"), ...administrationAliases],
                showWhen: user("canReadAtLeastOneScript"),
                route: "/administration/library/scripting/scripts",
                baseRoute: "/administration/library/scripting", // needed for keeping the subtab highlighted when there are tabs
              },
            ]
          : []),
      ],
    },

    // organizations tab
    ...(user("canViewAtLeastOneOrganization")
      ? [
          {
            name: localized("Organizations"),
            to: "/administration/customers/list",
            searchAliases: administrationAliases,
          },
        ]
      : []),
    // policies tab
    {
      name: localized("Policies"),
      showWhen: user("canReadAtLeastOnePolicy"),
      to: "/administration/policies/agent",
      baseUrl: "/administration/policies",
      isActive: routeStartsWith("/administration/policies"),
      subtabs: [
        {
          name: localized("Agent Policies"),
          route: "/administration/policies/agent",
          searchAliases: administrationAliases,
        },
        {
          name: localized("NMS Policies"),
          route: "/administration/policies/nms",
          searchAliases: administrationAliases,
        },
        {
          name: localized("VM Policies"),
          route: "/administration/policies/virtualization",
          searchAliases: administrationAliases,
        },
        ...(isMDMFeatureEnabled()
          ? [
              {
                name: localized("MDM Policies"),
                route: "/administration/policies/mdm",
                searchAliases: administrationAliases,
              },
            ]
          : []),
      ],
    },
    {
      name: localized("Tasks"),
      showWhen: user("canViewScheduledTasks"),
      to: "/administration/tasks",
      baseUrl: "/administration/tasks",
      isActive: routeStartsWith("/administration/tasks"),
      searchAliases: administrationAliases,
    },
  ]

  return administrationNavItems
}

const flattenAdministrationNavItems = (items, isSubtab, parent) => {
  return items.reduce((flattenedItems, obj) => {
    if (obj.show === false || obj.showWhen === false) {
      return flattenedItems
    }

    if (isSubtab) {
      obj.category = parent.name
    }

    return obj.subtabs && !parent
      ? [...flattenedItems, ...flattenAdministrationNavItems(obj.subtabs, true, obj)]
      : [...flattenedItems, obj]
  }, [])
}

export const getFlattenedAdministrationNavItems = ({ appInfoCustomData } = {}) => {
  const navItems = getAdministrationNavItems({ appInfoCustomData })
  return flattenAdministrationNavItems(navItems)
}

const formatTabName = tabName => {
  return tabName?.replace("/", "") ?? ""
}

export const parsePathForAdministration = path => {
  const matchPath = /^#(\/\w+)?(\/\w+)?(\/\w+)?(\/\w+)?((\/[\w-]*)*)$/
  const [urlPath] = path.split("?")

  const [fullPath, dashboard, tabName, subTabName, subSubTabName, other, last] = match(matchPath, urlPath)

  return {
    fullPath,
    dashboard,
    tabName: formatTabName(tabName),
    subTabName: formatTabName(subTabName),
    subSubTabName: formatTabName(subSubTabName),
    other,
    last,
  }
}

const _appInfoCustomDataCache = {
  appInfoCustomData: {},
}
export const buildAppInfoCustomData = psaState => {
  const genericWebhookConnectionData = psaState?.GenericWebhook?.connection?.data || {}
  const data = {
    appInfoCustomData: {
      ...(genericWebhookConnectionData.enabled && {
        [PSA_TYPES.GENERIC_WEBHOOK]: {
          name: genericWebhookConnectionData.integrationName,
          description: genericWebhookConnectionData.integrationDescription,
        },
      }),
    },
  }

  if (fastDeepEqual(data, _appInfoCustomDataCache)) {
    return _appInfoCustomDataCache
  }
  _appInfoCustomDataCache.appInfoCustomData = data.appInfoCustomData
  return data
}
