import React, { PureComponent } from "react"
import moment from "moment"
import { FormGroup, ControlLabel } from "react-bootstrap"
import { mergeRight } from "ramda"
import Datetime from "react-datetime"
import { localized, serverScheduleDateFormat, getDateTimeLocale } from "js/includes/common/utils"
import DatetimePickerWithTimezone from "js/includes/components/DatetimePickerWithTimezone"
import { getDivisionTimeZone } from "js/includes/common/utils/ssrAndWebUtils"
import { StyledCol } from "js/includes/components/Styled/Form"
import { Box } from "js/includes/components/Styled"
import Tooltip from "js/includes/components/Tooltip"

export default class ScheduleStart extends PureComponent {
  changeStartDate = datetime => {
    const { schedule, updateSchedule, dateFormat = serverScheduleDateFormat, isValidStartDate } = this.props
    const date = getDateTimeLocale(datetime)
    if (date.isValid() && (!isValidStartDate || isValidStartDate(date))) {
      updateSchedule(
        mergeRight(schedule, {
          startDate: date.format(dateFormat),
          runAtHour: date.hour(),
          runAtMinute: date.minute(),
        }),
      )
    } else {
      updateSchedule(schedule)
    }
  }

  isPastDate = datetime => {
    const divisionTimezone = getDivisionTimeZone()
    const today = moment.tz(divisionTimezone)

    return datetime
      .clone()
      .tz(divisionTimezone)
      .isBefore(today, "day")
  }

  render() {
    const {
      schedule,
      visibleTimeZone = true,
      startAtInfo,
      dateFormat = serverScheduleDateFormat,
      isValidStartDate,
    } = this.props
    const { startDate, runAtHour, runAtMinute } = schedule
    const startDateTime = moment(startDate, dateFormat)
    startDateTime.hour(runAtHour)
    startDateTime.minute(runAtMinute)

    const showInvalidDateTooltip = this.isPastDate(startDateTime)

    const dateTimePickerComponent = (
      <StyledCol xs={9}>
        {visibleTimeZone ? (
          <DatetimePickerWithTimezone
            dateTime={getDateTimeLocale(startDateTime)}
            onDateTimeChange={this.changeStartDate}
            isValidDate={isValidStartDate}
          />
        ) : (
          <Box flex={1}>
            <Datetime
              value={getDateTimeLocale(startDateTime)}
              onChange={this.changeStartDate}
              isValidDate={isValidStartDate}
            />
          </Box>
        )}
      </StyledCol>
    )

    return (
      <FormGroup>
        <StyledCol componentClass={ControlLabel} xs={3} className="text-align-left">
          <span>{localized("Start At")}</span>
          {startAtInfo && <Tooltip output={startAtInfo} />}
        </StyledCol>
        {showInvalidDateTooltip ? (
          <Tooltip output={localized("You can't select a date / time in the past")} placement="left">
            {dateTimePickerComponent}
          </Tooltip>
        ) : (
          <>{dateTimePickerComponent}</>
        )}
      </FormGroup>
    )
  }
}
