import { useEffect } from "react"
import { connect } from "react-redux"
import styled from "@emotion/styled"
import { sizer } from "@ninjaone/utils"
import { Modal, Input, Select, Text, AlertMessage } from "@ninjaone/components"
import { useForm, useMountedState } from "js/includes/common/hooks"
import {
  localizationKey,
  localized,
  showSuccessMessage,
  validations,
  reportErrorAndShowMessage,
} from "js/includes/common/utils"
import { getScriptCategoriesSelectOptions } from "js/state/selectors/scripting/scriptCategories"
import { createApplicationAutomation, updateApplicationAutomation } from "js/includes/common/client"
import {
  ARCHITECTURE_32,
  ARCHITECTURE_64,
  ARCHITECTURE_ALL,
  getArchitectureOptions,
  fetchAutomationsResource,
} from "js/includes/components/scripting/ScriptsSelector/ParameterModal/ParameterComponents/InstallApplication/utils"
import { getCredentialSelectOptions } from "js/includes/components/scripting/ScriptsSelector/ParameterModal/ParameterComponents/common/credentials"
import CategoriesSelect from "js/includes/components/scripting/ScriptsSelector/ParameterModal/ParameterComponents/common/CategoriesSelect"
import InstallRunApplicationModalContent from "js/includes/components/scripting/ScriptsSelector/ParameterModal/ParameterComponents/common/InstallRunApplicationModalContent"
import Parameters from "js/includes/configuration/scripting/ScriptsSubTabModel/ScriptEditor/Parameters"
import { Box } from "js/includes/components/Styled"
import { requestAllScriptCategories as _requestAllScriptCategories } from "js/state/actions/scripting/scriptCategories"
import { requestAllScripts as _requestAllScripts } from "js/state/actions/scripting/scripts"
import { uncategorizedIfNoCategories } from "js/includes/configuration/scripting/scriptingUtils"

const StyledFormLabelContainer = styled.div`
  margin: ${sizer(2, 0, 1)};
`

function RunApplicationModal({
  editingRunApplication = {},
  scriptCategories,
  unmount,
  requestAllScripts,
  requestAllScriptCategories,
  setAutomationsUnderReview,
}) {
  const [credentialOptions, setCredentialOptions] = useMountedState([])
  const [errorMessage, setErrorMessage] = useMountedState("")
  const [saving, setSaving] = useMountedState(false)
  const {
    values: {
      name,
      description,
      categories,
      architecture,
      path,
      parameters,
      useFirstParametersOptionAsDefault,
      credential,
    },
    validation,
    validateForm,
    onChange,
  } = useForm({
    fields: {
      name: editingRunApplication?.name || "",
      description: editingRunApplication?.description || "",
      categories: editingRunApplication?.categoriesIds?.map(cat => cat.toString()) || [],
      architecture:
        editingRunApplication?.architecture?.length > 1
          ? ARCHITECTURE_ALL
          : editingRunApplication?.architecture?.[0] || ARCHITECTURE_ALL,
      parameters: editingRunApplication.scriptParameters || [],
      useFirstParametersOptionAsDefault: editingRunApplication.useFirstParametersOptionAsDefault || false,
      credential: editingRunApplication?.binaryRunSettings?.runAs || "",
      path: editingRunApplication?.binaryRunSettings?.runnableLocalPath || "",
    },
    validate: {
      name: validations.required,
      path: validations.required,
    },
  })

  useEffect(() => {
    const setCredentials = async () => {
      const credentials = await getCredentialSelectOptions("Windows", "labelText")
      setCredentialOptions(credentials)
      !credential && onChange({ credential: credentials[0]?.value || "" })
    }
    setCredentials()
  }, [credential, setCredentialOptions, onChange])

  const saveRunApplication = async () => {
    setErrorMessage("")
    if (!validateForm()) return
    setSaving(true)

    try {
      const automation = {
        architecture: architecture === ARCHITECTURE_ALL ? [ARCHITECTURE_64, ARCHITECTURE_32] : [architecture],
        scriptParameters: parameters,
        useFirstParametersOptionAsDefault,
        name,
        description,
        categoriesIds: uncategorizedIfNoCategories(categories),
        language: "binary_run",
        operatingSystems: ["Windows"],
        binaryRunSettings: {
          runnableLocalPath: path,
          runAs: credential,
        },
      }

      editingRunApplication.id
        ? await updateApplicationAutomation({
            ...automation,
            id: editingRunApplication.id,
          })
        : await createApplicationAutomation(automation)

      unmount()
      showSuccessMessage()
      await fetchAutomationsResource({ requestAllScripts, requestAllScriptCategories, setAutomationsUnderReview })
    } catch (error) {
      setSaving(false)
      setErrorMessage(localized("Something went wrong. Please try again."))
      reportErrorAndShowMessage(error)
    }
  }

  return (
    <Modal
      cancelable
      unmount={unmount}
      size="lg"
      titleGroup={{
        titleToken: localizationKey("Run Application"),
        descriptionToken: localizationKey("Automate app execution by defining a run automation"),
      }}
      buttons={[
        {
          type: "save",
          labelToken: localizationKey("Save"),
          onClick: saveRunApplication,
          disabled: saving,
        },
      ]}
    >
      <InstallRunApplicationModalContent>
        <StyledFormLabelContainer>
          <Text bold size="sm" token={localizationKey("Name")} />
        </StyledFormLabelContainer>
        <Input value={name} onChange={e => onChange({ name: e.target.value })} errorMessage={validation.message.name} />
        <StyledFormLabelContainer>
          <Text bold size="sm" token={localizationKey("Description (Optional)")} />
        </StyledFormLabelContainer>
        <Input value={description} onChange={e => onChange({ description: e.target.value })} />
        <StyledFormLabelContainer>
          <Text bold size="sm" token={localizationKey("Categories")} />
        </StyledFormLabelContainer>
        <CategoriesSelect {...{ categories, scriptCategories, onChange }} />
        <StyledFormLabelContainer>
          <Text bold size="sm" token={localizationKey("Architecture")} />
        </StyledFormLabelContainer>
        <Select
          options={getArchitectureOptions()}
          onChange={architecture => onChange({ architecture })}
          value={architecture}
          triggerAriaLabel={localized("Architecture")}
        />
        <StyledFormLabelContainer>
          <Text bold size="sm" token={localizationKey("Path")} />
        </StyledFormLabelContainer>
        <Input
          value={path}
          onChange={e => onChange({ path: e.target.value })}
          errorMessage={validation.message.path}
          placeholder={localizationKey("Path to EXE")}
        />
        <StyledFormLabelContainer>
          <Text bold size="sm" token={localizationKey("Run As")} />
        </StyledFormLabelContainer>
        <Select options={credentialOptions} onChange={credential => onChange({ credential })} value={credential} triggerAriaLabel={localized("Run As")}/>
        <Parameters
          parameters={parameters}
          onParametersChanged={params => onChange({ parameters: params })}
          labelText={localized("Parameters (Optional)")}
          useFirstParametersOptionAsDefault={useFirstParametersOptionAsDefault}
          onFirstParametersOptionChanged={useFirstParametersOptionAsDefault => {
            onChange({ useFirstParametersOptionAsDefault })
          }}
        />
        {errorMessage && (
          <Box marginTop={sizer(2)}>
            <AlertMessage variant="danger" labelToken={errorMessage} />
          </Box>
        )}
      </InstallRunApplicationModalContent>
    </Modal>
  )
}

export default connect(state => ({ scriptCategories: getScriptCategoriesSelectOptions(state) }), {
  requestAllScripts: _requestAllScripts,
  requestAllScriptCategories: _requestAllScriptCategories,
})(RunApplicationModal)
